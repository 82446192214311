import axios from 'axios'
import { DOMAIN } from '@/_helpers/constants'
import { authHeader } from '@/_helpers/auth-header'
import VuexStore from '@/store'
import { ToastProgrammatic as Toast } from 'buefy'

export function oaxios(
  url,
  data = {},
  method = 'post',
  responseType = 'application/json',
) {
  const requestData = {
    ...data,
    domain: DOMAIN,
  }

  const requestConfig = {
    responseType: responseType,
    method: method,
    url: url,
    headers: authHeader(),
    data: requestData,
  }

  return axios(requestConfig).catch((e) => {
    if (
      url !== '/logout' &&
      e.response &&
      e.response.status === 401 &&
      VuexStore.state.account.loggedIn
    ) {
      VuexStore.dispatch('account/logout', null, { root: true }).then(() => {})
    } else if (e.response.status !== 401 && e.response.status !== 422) {
      /* Toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
      }) */
    }
    throw e
  })
}
