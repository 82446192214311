import store from '@/store'

export function authHeader() {
  const accessToken = store.state.account.accessToken

  if (accessToken) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    }
  } else {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }
}
