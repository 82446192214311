import { oaxios } from '@/_helpers'

const state = {
  salutations: null,
  titles: null,
  countries: null,
  all_countries: null,
  order_types: null,
  doctype: null,
  banks: [],
  employerbank: null,
  crms: [],
  roles: [],
  min_order_value: 0,
  max_order_value: 10,
  days_of_bill: [],
  categories: [],
  required_fields: [],
  customer_extra_fields: [],
  commission: 0,
  commissions: [],
  assets_origins: [],
  usage_backgrounds: [],
}

const actions = {
  fetchConst({ commit }) {
    return oaxios('/helper/const')
      .then((response) => {
        commit('setConst', response.data)
      })
      .catch(() => {})
  },
}

const mutations = {
  setConst(state, constants) {
    state.salutations = constants.salutations
    state.titles = constants.titles
    state.countries = constants.countries
    state.all_countries = constants.all_countries
    state.order_types = constants.order_types
    state.doctype = constants.doctype
    state.banks = constants.banks
    state.employerbank = constants.banks
    state.crms = constants.crms
    state.roles = constants.roles
    state.min_order_value = constants.min_order_value
    state.max_order_value = constants.max_order_value
    state.days_of_bill = constants.days_of_bill
    state.categories = constants.categories
    state.required_fields = constants.required_fields
    state.customer_extra_fields = constants.customer_extra_fields
    state.commission = constants.commission
    state.commissions = constants.commissions
    state.assets_origins = constants.assets_origin
    state.usage_backgrounds = constants.usage_background
  },
  addBank(state, bank) {
    state.banks[bank.id] = bank.name
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
