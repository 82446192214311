<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false"> Dashboard </hero-bar>
    <section class="section is-main-section">
      <div class="dashboard-buttons">
        <span
          @click="downloadDocument('Beschreibung_der_Geschaeftsprozesse.pdf')"
          class="button"
        >
          Beschreibung der Geschaftsprozesse
        </span>
        <span
          @click="downloadDocument('Goldsparplan_Produktinformationsblatt.pdf')"
          class="button"
        >
          Produktinformationsblatt
        </span>
        <span
          @click="
            downloadDocument('FAQs_Bank_Goldsparplan_FAQs_5.0_ohne_agree21.pdf')
          "
          class="button"
        >
          FAQ
        </span>
        <a :href="agbPath" class="button" target="_blank"> AGB </a>
        <a :href="privacyPath" class="button" target="_blank">
          Datenschutzbestimmungen
        </a>
        <span
          @click="downloadDocument('Bestellformular_Werbematerialien.pdf')"
          class="button"
        >
          Bestellformular Werbemittel
        </span>
        <span
          @click="
            downloadDocument(
              'RB_2021_12_Flyer_Goldsparplan_RZ_ansicht_einzeln.pdf',
            )
          "
          class="button"
        >
          Flyer zum Goldsparplan
        </span>
        <span
          @click="
            downloadDocument(
              'RB427_2022_02_Flyer_Gold-Tresor_Enkunde_RZ_Ansicht.pdf',
            )
          "
          class="button"
        >
          Flyer Gold Tresor
        </span>
        <span
          @click="
            downloadDocument(
              'Berater-Kurzinfo_Aenderungen_im_neuen_GSP_Portal.pdf',
            )
          "
          class="button"
        >
          Berater-Kurzinfo:<br />Änderungen im neuen GSP Portal
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import * as chartConfig from '@/components/Charts/chart.config'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { oaxios } from '@/_helpers/'

export default {
  name: 'Home',
  components: {
    HeroBar,
    TitleBar,
  },
  data() {
    return {
      defaultChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsMain,
      },
      agbPath: process.env.VUE_APP_FILE_PATH_AGB,
      privacyPath: process.env.VUE_APP_FILE_PATH_PRIVACY,
      downloadName: null,
    }
  },
  computed: {
    titleStack() {
      return ['Dashboard']
    },
  },
  mounted() {
    this.fillChartData()

    console.log(process.env.VUE_APP_FILE_PATH_AGB)
    console.log(process.env.VUE_APP_FILE_PATH_PRIVACY)
    /* this.$buefy.snackbar.open({
      message: 'Welcome back',
      queue: false,
    }) */
  },
  methods: {
    randomChartData(n) {
      const data = []

      for (let i = 0; i < n; i++) {
        data.push(Math.round(Math.random() * 200))
      }

      return data
    },
    fillChartData() {
      this.defaultChart.chartData = {
        datasets: [
          {
            label: 'Data1',
            fill: false,
            borderColor: chartConfig.chartColors.default.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.randomChartData(19),
          },
          {
            label: 'Data2',
            fill: false,
            borderColor: chartConfig.chartColors.default.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.randomChartData(19),
          },
          {
            label: 'Data3',
            fill: false,
            borderColor: chartConfig.chartColors.default.danger,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.danger,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.danger,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.randomChartData(19),
          },
        ],
        labels: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
        ],
      }
    },
    downloadDocument(name) {
      this.downloadName = name
      const data = { document: name }
      oaxios('/document/download', data, 'post', 'blob').then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/pdf',
          }),
        )

        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', this.downloadName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  },
}
</script>
