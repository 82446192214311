<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <i
        class="navbar-item is-hidden-desktop"
        @click.prevent="menuToggleMobile"
      >
        <b-icon :icon="menuToggleMobileIcon" />
      </i>
    </div>
    <div class="navbar-brand is-right">
      <i
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </i>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <div class="is-user-name">
            <span>Mein Profil</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <router-link
              to="/profile"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="account" custom-size="default" />
              <span>Profil bearbeiten</span>
            </router-link>
            <!--            <router-link
              to="/totp"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="fingerprint" custom-size="default" />
              <span>2FA</span>
            </router-link>-->
            <hr class="navbar-divider" />
            <i class="navbar-item" @click="logoutClick">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Abmelden</span>
            </i>
          </div>
        </nav-bar-menu>
        <a
          class="navbar-item is-desktop-icon-only"
          title="Abmelden"
          @click="logoutClick"
        >
          <b-icon icon="logout" custom-size="default" />
          <span>Abmelden</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'

export default {
  name: 'NavBar',
  components: {
    NavBarMenu,
  },
  data() {
    return {
      isMenuNavBarActive: false,
    }
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    ...mapState('account', ['user']),
    ...mapState(['isNavBarVisible', 'isAsideMobileExpanded', 'userName']),
  },
  methods: {
    ...mapActions('account', ['logout']),
    menuToggleMobile() {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive
    },
    logoutClick() {
      /* this.$buefy.snackbar.open({
        message: 'Log out clicked',
        queue: false,
      }) */
      this.logout()
    },
  },
}
</script>
