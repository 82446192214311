import 'print-js/dist/print.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
// import './registerServiceWorker'

/* VeeValidator */
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import de from 'vee-validate/dist/locale/de.json'
import vuetify from '@/plugins/vuetify'

/* Cleave Input */
import Cleave from '@/components/Cleave'

/* Vue. Main component */
import App from './App.vue'

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE

/* Default title tag */
const defaultDocumentTitle = 'Reisebank Portal'

import('./assets/MaterialDesign-Webfont-6.5.95/css/materialdesignicons.min.css')
import(
  /* webpackChunkName: "theme" */
  /* webpackMode: "lazy" */
  `./scss/themes/${window.location.hostname}.scss`
).catch(() => {
  import('./scss/themes/default.scss')
})

/* VeeValidate */
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('de', de)

// eslint-disable-next-line no-extend-native
Array.prototype.remove = function () {
  let what
  const a = arguments
  let L = a.length
  let ax
  while (L && this.length) {
    what = a[--L]
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1)
    }
  }
  return this
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
// eslint-disable-next-line no-extend-native
String.prototype.snake2Pascal = function () {
  let str = this
  str += ''
  str = str.split('_')
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
  }
  return str.join('')
}

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach((to) => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(Cleave)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
