import Vue from 'vue'
import VueRouter from 'vue-router'

import VuexStore from '@/store'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    meta: {
      title: 'Dashboard',
      protected: true,
    },
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    meta: {
      title: 'Login',
      protected: false,
    },
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "authentication" */ '../views/Login.vue'),
  },
  {
    meta: {
      title: 'Passwort vergessen',
      protected: false,
    },
    path: '/forgot-password',
    name: 'password.forgot',
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ '../views/ForgotPassword.vue'
      ),
  },
  {
    meta: {
      title: 'Passwort setzen',
      protected: false,
    },
    path: '/reset-password',
    name: 'password.reset',
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ '../views/ResetPassword.vue'
      ),
    children: [
      {
        meta: {
          title: 'Passwort setzen',
          protected: false,
        },
        path: ':token',
        component: () =>
          import(
            /* webpackChunkName: "authentication" */ '../views/ResetPassword.vue'
          ),
      },
    ],
  },
  {
    meta: {
      title: 'Profile',
      protected: true,
    },
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    meta: {
      title: 'TOTP',
      protected: true,
    },
    path: '/totp',
    name: 'totp',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/TOTP.vue'),
  },
  {
    meta: {
      title: 'Statistiken',
      protected: true,
    },
    path: '/stats',
    name: 'stats.list',
    component: () =>
      import(/* webpackChunkName: "statistics" */ '../views/Stats.vue'),
  },
  {
    meta: {
      title: 'Banken',
      protected: true,
    },
    path: '/bank',
    name: 'bank.list',
    component: () =>
      import(/* webpackChunkName: "branch" */ '../views/Banks.vue'),
  },
  {
    meta: {
      title: 'Neue Bank',
      protected: true,
    },
    path: '/bank/new',
    name: 'bank.new',
    component: () =>
      import(/* webpackChunkName: "branch" */ '../views/BankForm.vue'),
  },
  {
    meta: {
      title: 'Banken bearbeiten',
      protected: true,
    },
    path: '/bank/:id',
    name: 'bank.edit',
    component: () =>
      import(/* webpackChunkName: "branch" */ '../views/BankForm.vue'),
    props: true,
  },
  {
    meta: {
      title: 'Provisionen',
      protected: true,
    },
    path: '/commission',
    name: 'commission',
    component: () =>
      import(/* webpackChunkName: "commission" */ '../views/Commission.vue'),
    props: true,
  },
  {
    meta: {
      title: 'Mitarbeiter',
      protected: true,
    },
    path: '/employee',
    name: 'employee.list',
    component: () =>
      import(/* webpackChunkName: "employee" */ '../views/Employees.vue'),
  },
  {
    meta: {
      title: 'Neuer Mitarbeiter',
      protected: true,
    },
    path: '/employee/new',
    name: 'employee.new',
    component: () =>
      import(/* webpackChunkName: "employee" */ '../views/EmployeeForm.vue'),
  },
  {
    meta: {
      title: 'Bearbeiten Mitarbeiter',
      protected: true,
    },
    path: '/employee/:id',
    name: 'employee.edit',
    component: () =>
      import(/* webpackChunkName: "employee" */ '../views/EmployeeForm.vue'),
    props: true,
  },
  {
    meta: {
      title: 'Kundenübersicht',
      protected: true,
    },
    path: '/client',
    name: 'client.list',
    component: () =>
      import(/* webpackChunkName: "client" */ '../views/Clients.vue'),
  },
  {
    meta: {
      title: 'Neuer Kunde',
      protected: true,
    },
    path: '/client/new',
    name: 'client.new',
    component: () =>
      import(/* webpackChunkName: "client" */ '../views/ClientForm.vue'),
  },
  {
    meta: {
      title: 'Kunden bearbeiten',
      protected: true,
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () =>
      import(/* webpackChunkName: "client" */ '../views/ClientForm.vue'),
    props: true,
  },
  {
    meta: {
      title: 'Abrechnungen',
      protected: true,
    },
    path: '/order',
    name: 'order.list',
    component: () =>
      import(/* webpackChunkName: "order" */ '../views/Orders.vue'),
  },
  {
    meta: {
      title: 'Einmalkauf',
      protected: true,
    },
    path: '/order/create',
    name: 'order.create',
    component: () =>
      import(/* webpackChunkName: "order" */ '../views/OneTimeOrder.vue'),
  },
  {
    meta: {
      title: 'Einmalkauf',
      protected: true,
    },
    path: '/order/create/:id',
    name: 'order.create.id',
    component: () =>
      import(/* webpackChunkName: "order" */ '../views/OneTimeOrder.vue'),
  },
  {
    meta: {
      title: 'Versenden',
      protected: true,
    },
    path: '/order/shipping/:id/:vault',
    name: 'order.shipping',
    component: () =>
      import(/* webpackChunkName: "order" */ '../views/ShippingOrder.vue'),
  },
  {
    meta: {
      title: 'Verkaufen',
      protected: true,
    },
    path: '/order/purchase/:id/:vault',
    name: 'order.purchase',
    component: () =>
      import(/* webpackChunkName: "order" */ '../views/PurchaseOrder.vue'),
  },
  {
    meta: {
      title: 'Sparpläne',
      protected: true,
    },
    path: '/saving-plan',
    name: 'saving-plan.list',
    component: () =>
      import(/* webpackChunkName: "saving-plan" */ '../views/SavingPlans.vue'),
  },
  {
    meta: {
      title: 'Sparplan bearbeiten',
      protected: true,
    },
    path: '/saving-plan/:id',
    name: 'saving-plan.edit',
    component: () =>
      import(
        /* webpackChunkName: "saving-plan" */ '../views/SavingPlanEdit.vue'
      ),
  },
  {
    meta: {
      title: 'Sparplan anlegen',
      protected: true,
    },
    path: '/saving-plan/create',
    name: 'saving-plan.create',
    component: () =>
      import(
        /* webpackChunkName: "saving-plan" */ '../views/SavingPlanCreate.vue'
      ),
  },
  {
    meta: {
      title: 'Sparplan anlegen',
      protected: true,
    },
    path: '/saving-plan/create/:id',
    name: 'saving-plan.create.id',
    component: () =>
      import(
        /* webpackChunkName: "saving-plan" */ '../views/SavingPlanCreate.vue'
      ),
  },
  {
    meta: {
      protected: true,
    },
    path: '/pdf/:file',
  },
  {
    meta: {
      title: '403',
      protected: true,
    },
    path: '/403',
    name: 'error.nopermission',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/error/NoPermission'),
  },
  {
    meta: {
      title: '404',
      protected: true,
    },
    path: '/*',
    name: 'error.notfound',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/error/NotFound'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.protected && !VuexStore.state.account.loggedIn) next('/login')
  else next()
})

export default router
