import { oaxios } from '@/_helpers'
import router from '@/router'

const accessToken = JSON.parse(
  sessionStorage.getItem('accessToken')
    ? sessionStorage.getItem('accessToken')
    : null,
)
const user = JSON.parse(
  sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '{ }',
)
const state = {
  accessToken,
  loggedIn: !!accessToken,
  requested_totp: false,
  user: null,
}
state.user = user
  ? {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      role: user.role,
    }
  : {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
    }

const actions = {
  loginWithTOTP({ dispatch, commit }, { email, password, totp }) {
    const data = {
      email: email,
      password: password,
      token: totp,
    }

    return oaxios('/2fa/check', data)
      .then((response) => {
        commit('changeRequestedTOTP', response.data.need_2fa)
        if (!response.data.need_2fa) {
          commit('login', response.data.success)
          dispatch('activityTracker/activateActivityTracker', null, {
            root: true,
          })
          dispatch('helper/fetchConst', null, { root: true })
          dispatch('permission/list', null, { root: true })
          router.push('/')
        }
      })
      .catch((error) => {
        throw error
      })
  },
  login({ dispatch, commit }, { email, password }) {
    const data = {
      email: email,
      password: password,
    }

    return oaxios('/login', data)
      .then((response) => {
        commit('changeRequestedTOTP', response.data.need_2fa)
        if (!response.data.need_2fa) {
          commit('login', response.data.success)
          dispatch('activityTracker/activateActivityTracker', null, {
            root: true,
          })
          dispatch('helper/fetchConst', null, { root: true })
          dispatch('permission/list', null, { root: true })
          router.push('/')
        }
      })
      .catch((error) => {
        throw error
      })
  },
  logout({ dispatch, commit }) {
    return oaxios('/logout')
      .catch(() => {})
      .finally(() => {
        sessionStorage.removeItem('timer')
        sessionStorage.setItem('logout', true)
        commit('logout')
        dispatch('activityTracker/deactivateActivityTracker', null, {
          root: true,
        })
        if (router.currentRoute.name !== 'login') {
          router.push({ name: 'login' }).then(() => {})
        }
      })
  },
}

const mutations = {
  changeRequestedTOTP(state, requested) {
    state.requested_totp = requested
  },
  setUser(state, user) {
    state.user = user
  },
  login(state, data) {
    state.accessToken = data.token
    state.user = data.user
    state.loggedIn = true
    sessionStorage.setItem('accessToken', JSON.stringify(data.token))
    sessionStorage.setItem('user', JSON.stringify(data.user))
  },
  logout(state) {
    state.accessToken = null
    state.user.first_name = null
    state.user.last_name = null
    state.user.email = null
    state.user.role_id = null
    state.loggedIn = false
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('user')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
