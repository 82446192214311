<template>
  <b-input
    v-model="maskedValue"
    @input.native="onInput"
    @blur="onBlur"
    v-bind="$attrs"
  ></b-input>
</template>

<script>
import Cleave from 'cleave.js'
require('cleave.js/dist/addons/cleave-phone.de')

export default {
  name: 'cleave',
  props: {
    value: {
      default: null,
      required: true,
      validator(value) {
        return (
          value === null ||
          typeof value === 'string' ||
          value instanceof String ||
          typeof value === 'number'
        )
      },
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    raw: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maskedValue: null,
      cleave: null,
    }
  },
  methods: {
    onInput(event) {
      if (this.raw) {
        this.$emit('input', this.cleave.getRawValue())
      } else {
        this.$emit('input', this.cleave.getFormattedValue())
      }
      const formattedValue = this.cleave.getFormattedValue()
      this.$nextTick(() => {
        if (event.target) {
          this.maskedValue = formattedValue
        }
      })
    },
    onBlur() {
      const rawValue = this.cleave.getRawValue()
      if (this.raw) {
        this.$emit('blur', this.cleave.getRawValue())
      } else {
        this.$emit('blur', this.cleave.getFormattedValue())
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.cleave.setRawValue(rawValue)
        }, 0)
      })
    },
  },
  watch: {
    value(newValue) {
      this.$emit('change')
      const input = this.$el.querySelector('input')
      if (this.raw && newValue === this.cleave.getRawValue()) return
      if (!this.raw && newValue === input.value) return
      const rawValue = newValue
      this.$nextTick(() => {
        setTimeout(() => {
          this.cleave.setRawValue(rawValue)
          this.maskedValue = this.cleave.getFormattedValue()
        }, 1)
      })
    },
  },
  mounted() {
    /* istanbul ignore if */
    if (this.cleave) return

    this.cleave = new Cleave(this.$el.querySelector('input'), this.options)
  },
  beforeDestroy() {
    /* istanbul ignore if */
    if (!this.cleave) return

    this.cleave.destroy()
    this.cleave = null
  },
}
</script>

<style scoped></style>
