<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <b-image slot="logo" :src="logo_url" />
    </aside-tools>
    <div class="activity-counter">
      <span class="icon">
        <i class="mdi default mdi-av-timer"></i>
      </span>
      <span>{{ logoutTime }}</span>
    </div>
    <template v-if="navBuild === true">
      <div class="menu is-menu-main" :key="navReady">
        <template v-for="(menuGroup, index) in menu">
          <aside-menu-list
            :key="index"
            :menu="menuGroup"
            @menu-click="menuClick"
          />
        </template>
      </div>
    </template>
  </aside>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AsideTools from '@/components/AsideTools'
import AsideMenuList from '@/components/AsideMenuList'
import {
  BACKEND_BASE,
  DOMAIN,
  oaxios,
  INACTIVE_USER_TIME_THRESHOLD,
} from '@/_helpers'

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inactive: 0,
      logoutTime: '20:00',
      clockTimeOut: {},
      logo_url: BACKEND_BASE + 'helper/logo?domain=' + DOMAIN,
      navReady: 0,
      navBuild: false,
      reload: [
        {
          to: '/stats',
          icon: 'chart-areaspline',
          label: 'Statistik',
          permission: 'stats',
        },
        {
          to: '/bank',
          icon: 'store',
          label: 'Banken',
          permission: 'bank.list',
        },
        {
          to: '/commission',
          icon: 'currency-usd',
          label: 'Provision',
          permission: 'commission.list',
        },
        {
          to: '/employee',
          icon: 'face-agent',
          label: 'Mitarbeiter',
          permission: 'employee.get',
        },
        {
          to: '/client',
          icon: 'account-group',
          label: 'Kunden',
          permission: 'customer.list',
        },
        {
          to: '/order',
          icon: 'format-list-bulleted',
          label: 'Abrechnungen',
          permission: 'order.list',
        },
        {
          to: '/saving-plan',
          icon: 'piggy-bank',
          label: 'Sparpläne und Bestände',
          permission: 'saving-plan.list',
        },
      ],
    }
  },
  computed: {
    ...mapState(['isAsideVisible']),
    ...mapState('account', ['loggedIn']),
  },
  methods: {
    ...mapActions('account', ['logout']),
    menuClick(item) {
      //
    },
    completeMenu() {
      let newMenu = this.menu
      this.reload.forEach(function (entry) {
        const next = {
          to: entry.to,
          icon: entry.icon,
          label: entry.label,
          permission: entry.permission,
        }
        newMenu[1].push(next)
      })
      this.navReady += 1
      newMenu = null
    },
    setClock(elapsed) {
      if (elapsed === 0) {
        const oldTimer = sessionStorage.getItem('timer')
        if (!oldTimer) {
          if (!sessionStorage.getItem('logout'))
            sessionStorage.setItem('timer', Date.now())
        } else {
          clearTimeout(this.clockTimeOut)
          const inactive = (Date.now() - oldTimer) / 1000
          if (INACTIVE_USER_TIME_THRESHOLD / 1000 <= inactive) {
            clearTimeout(this.clockTimeOut)
            this.logout()
          }
        }
      } else {
        this.inactive = parseInt(this.inactive) + parseInt(elapsed)
      }
      const max = INACTIVE_USER_TIME_THRESHOLD / 1000
      this.clockTimeOut = setTimeout(() => {
        this.inactive++
        const t = max - this.inactive
        const min = t >= 0 ? Math.floor(t / 60) : 0
        const sec = t >= 0 ? (min > 0 ? t - min * 60 : t) : 0
        const minutes = '0' + min
        const seconds = '0' + sec
        this.logoutTime = minutes.substr(-2) + ':' + seconds.substr(-2)
        if (t <= 0) {
          if (this.loggedIn) {
            clearTimeout(this.clockTimeOut)
            window.removeEventListener('mousedown', this.clearData)
            window.removeEventListener('keydown', this.clearData)
            document.removeEventListener('visibilitychange', this.setDelay)
            sessionStorage.removeItem('timer')
            this.logout()
          }
        } else {
          this.setClock(0)
        }
      }, 1000)
    },
    clearData() {
      if (this.loggedIn) {
        clearTimeout(this.clockTimeOut)
        this.inactive = 0
        this.logoutTime = '20:00'
        sessionStorage.removeItem('timer')
        sessionStorage.removeItem('logout')
        this.setClock(0)
      }
    },
    setDelay() {
      if (document.hidden) {
        clearTimeout(this.clockTimeOut)
      } else {
        const elapsed =
          (parseInt(Date.now()) - parseInt(sessionStorage.getItem('timer'))) /
          1000
        this.setClock(elapsed)
      }
    },
  },
  beforeMount() {
    const first = this.menu[1][0]
    this.menu[1] = []
    this.menu[1][0] = first
  },
  mounted() {
    this.setClock(0)
    window.addEventListener('mousedown', this.clearData)
    window.addEventListener('keydown', this.clearData)
    document.addEventListener('visibilitychange', this.setDelay)
    oaxios('/permissions')
      .then((response) => {
        this.completeMenu()
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        if (this.menu[1][1].permission !== undefined) this.navBuild = true
      })
    delete this.menu[0]
  },
}
</script>
