export const INACTIVE_USER_TIME_THRESHOLD = 1200000
export const USER_ACTIVITY_THROTTLER_TIME = 500
export const LOGOUT_USER_TIME_THRESHOLD = 60
export const DOMAIN = window.location.href.replace(
  new RegExp(window.location.pathname + '$'),
  '',
)
// export const DOMAIN = 'http://ophirum-sparplan.local'
export const BACKEND_BASE = process.env.VUE_APP_BACKEND_BASE
export const BACKEND_STORAGE_BASE = process.env.VUE_APP_BACKEND_STORAGE_BASE
