import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
  LOGOUT_USER_TIME_THRESHOLD,
} from '@/_helpers'

const state = {
  inactiveSince: 0,
  inactiveSinceInterval: null,
  isInactive: false,
  userActivityThrottlerTimeout: null,
  userActivityTimeout: null,
}

const dispatchUserActivityThrottler = []

const actions = {
  activateActivityTracker({ dispatch }) {
    window.addEventListener(
      'mousemove',
      (dispatchUserActivityThrottler[0] = () =>
        dispatch('userActivityThrottler')),
    )
    window.addEventListener(
      'scroll',
      (dispatchUserActivityThrottler[1] = () =>
        dispatch('userActivityThrottler')),
    )
    window.addEventListener(
      'keydown',
      (dispatchUserActivityThrottler[2] = () =>
        dispatch('userActivityThrottler')),
    )
    window.addEventListener(
      'resize',
      (dispatchUserActivityThrottler[3] = () =>
        dispatch('userActivityThrottler')),
    )
  },
  deactivateActivityTracker({ commit }) {
    // window.removeEventListener('mousemove', dispatchUserActivityThrottler[0])
    window.removeEventListener('scroll', dispatchUserActivityThrottler[1])
    window.removeEventListener('keydown', dispatchUserActivityThrottler[2])
    window.removeEventListener('resize', dispatchUserActivityThrottler[3])

    commit('clearUserActivityTimeout')
    commit('clearUserActivityThrottlerTimeout')

    commit('clearInactiveSince')
    commit('setActive')
  },
  resetUserActivityTimeout({ dispatch, commit }) {
    commit('clearUserActivityTimeout')
    commit(
      'setUserActivityTimeout',
      setTimeout(() => {
        dispatch('inactiveUserAction')
      }, INACTIVE_USER_TIME_THRESHOLD),
    )
  },
  userActivityThrottler({ dispatch, commit }) {
    if (state.isInactive) {
      commit('clearInactiveSince')
      commit('setActive')
    }

    if (!state.userActivityThrottlerTimeout) {
      commit(
        'setUserActivityThrottlerTimeout',
        setTimeout(() => {
          dispatch('resetUserActivityTimeout')

          commit('clearUserActivityThrottlerTimeout')
          commit('setUserActivityThrottlerTimeout', null)
        }, USER_ACTIVITY_THROTTLER_TIME),
      )
    }
  },
  inactiveUserAction({ dispatch, commit }) {
    commit('setInactive')
    commit(
      'setInactiveSinceInterval',
      setInterval(function () {
        if (state.inactiveSince >= LOGOUT_USER_TIME_THRESHOLD) {
          dispatch('account/logout', null, { root: true })
        } else {
          commit('incrementInactiveSince')
        }
      }, 1000),
    )
  },
}

const mutations = {
  setInactiveSinceInterval(state, interval) {
    state.inactiveSinceInterval = interval
  },
  clearInactiveSince(state) {
    clearInterval(state.inactiveSinceInterval)
    state.inactiveSinceInterval = null
    state.inactiveSince = 0
  },
  incrementInactiveSince(state) {
    state.inactiveSince += 1
  },
  setInactive(state) {
    state.isInactive = true
  },
  setActive(state) {
    state.isInactive = false
  },
  setUserActivityThrottlerTimeout(state, timeout) {
    state.userActivityThrottlerTimeout = timeout
  },
  setUserActivityTimeout(state, timeout) {
    state.userActivityTimeout = timeout
  },
  clearUserActivityThrottlerTimeout(state) {
    clearTimeout(state.userActivityThrottlerTimeout)
  },
  clearUserActivityTimeout(state) {
    clearTimeout(state.userActivityTimeout)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
