<template>
  <div id="app">
    <div v-if="loggedIn">
      <nav-bar />
      <aside-menu :menu="menu" />
      <router-view />
      <logout-modal />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import LogoutModal from '@/components/LogoutModal'

export default {
  name: 'Home',
  components: {
    AsideMenu,
    NavBar,
    LogoutModal,
  },
  computed: {
    ...mapState('account', ['loggedIn']),
    menu() {
      return [
        [{}],
        [
          {
            to: '/home',
            icon: 'desktop-mac-dashboard',
            label: 'Dashboard',
          },
        ],
      ]
    },
  },
  methods: {
    ...mapActions('helper', ['fetchConst']),
    ...mapActions('permission', ['list']),
  },
  created() {
    if (this.loggedIn) {
      this.list()
      this.fetchConst()
    }
  },
}
</script>
