import Vue from 'vue'
import Vuex from 'vuex'

import helper from './helper'
import account from './account'
import activityTracker from './activity-tracker'
import permission from './permission'
import alert from './alert'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    helper,
    account,
    activityTracker,
    permission,
    alert,
  },
  state: {
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },
  },
  actions: {},
})
