<template>
  <div class="aside-tools">
    <div class="aside-tools-label">
      <slot name="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AsideTools',
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
}
</script>
