<template>
  <ul :class="{ 'menu-list': !isSubmenuList }">
    <aside-menu-item
      v-for="(item, index) in filteredMenu"
      :key="index"
      :item="item"
      @menu-click="menuClick"
    />
  </ul>
</template>

<script>
import AsideMenuItem from '@/components/AsideMenuItem'
import { mapGetters } from 'vuex'

export default {
  name: 'AsideMenuList',
  components: {
    AsideMenuItem,
  },
  props: {
    isSubmenuList: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('permission', ['hasPermission']),
    filteredMenu() {
      return this.menu.filter(
        m => !m.permission || this.hasPermission(m.permission),
      )
    },
  },
  methods: {
    menuClick(item) {
      this.$emit('menu-click', item)
    },
  },
}
</script>
