const state = {
  notifications: [],
}

const actions = {
  alert({ commit }, { type, message, time = 3000 }) {
    switch (type) {
      case 'primary':
        commit('primary', { message, time })
        break
      case 'link':
        commit('link', { message, time })
        break
      case 'info':
        commit('info', { message, time })
        break
      case 'success':
        commit('success', { message, time })
        break
      case 'warning':
        commit('warning', { message, time })
        break
      case 'danger':
        commit('danger', { message, time })
        break
      case 'stopped':
        commit('danger', { message, time })
        break
      default:
        commit('default', { message, time })
        break
    }
  },
  remove({ commit }, index) {
    commit('remove', index)
  },
  clear({ commit }) {
    commit('clear')
  },
}

const mutations = {
  default(state, { message, time }) {
    state.notifications.unshift({
      message: message,
      time: time,
    })
  },
  primary(state, { message, time }) {
    state.notifications.unshift({
      type: 'is-primary',
      message: message,
      time: time,
    })
  },
  link(state, { message, time }) {
    state.notifications.unshift({
      type: 'is-link',
      message: message,
      time: time,
    })
  },
  info(state, { message, time }) {
    state.notifications.unshift({
      type: 'is-info',
      message: message,
      time: time,
    })
  },
  success(state, { message, time }) {
    state.notifications.unshift({
      type: 'is-success',
      message: message,
      time: time,
    })
  },
  warning(state, { message, time }) {
    state.notifications.unshift({
      type: 'is-warning',
      message: message,
      time: time,
    })
  },
  danger(state, { message, time }) {
    state.notifications.unshift({
      type: 'is-danger',
      message: message,
      time: time,
    })
  },
  remove(state, index) {
    state.notifications.splice(state.notifications.indexOf(index), 1)
  },
  clear(state) {
    state.notifications = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
