import { oaxios } from '@/_helpers'

const permissions = JSON.parse(
  sessionStorage.getItem('permissions')
    ? sessionStorage.getItem('permissions')
    : '[]',
)
const state = {
  list: permissions,
}

const getters = {
  hasPermission: state => permission => {
    return state.list.filter(p => p.startsWith(permission)).length > 0
  },
}

const actions = {
  list({ commit }) {
    return oaxios('/permissions')
      .then(response => {
        commit('setList', response.data)
      })
      .catch(error => {
        throw error
      })
  },
}

const mutations = {
  setList(state, permissions) {
    state.list = permissions
    sessionStorage.setItem('permissions', JSON.stringify(permissions))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
