<template>
  <div v-if="isInactive" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="base-timer">
        <svg
          class="base-timer__svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g class="base-timer__circle">
            <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
            <path
              id="base-timer-path-remaining"
              :stroke-dasharray="circleDasharray"
              class="base-timer__path-remaining is-primary"
              :stroke="strokeColor"
              d="
                                  M 50, 50
                                  m -45, 0
                                  a 45,45 0 1,0 90,0
                                  a 45,45 0 1,0 -90,0"
            ></path>
          </g>
        </svg>
        <span id="base-timer-label" class="base-timer__label has-text-white">
          {{ logoutTimeThreshold - inactiveSince }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGOUT_USER_TIME_THRESHOLD } from '@/_helpers'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LogoutModal',
  data() {
    return {
      logoutTimeThreshold: LOGOUT_USER_TIME_THRESHOLD,
      circleDasharray: 283,
      strokeColor: '#00FF00',
    }
  },
  computed: {
    ...mapState('account', ['loggedIn']),
    ...mapState('activityTracker', ['isInactive', 'inactiveSince']),
  },
  watch: {
    inactiveSince() {
      this.setCircleDasharray()
    },
  },
  methods: {
    ...mapActions('activityTracker', [
      'activateActivityTracker',
      'deactivateActivityTracker',
    ]),
    calculateTimeFraction() {
      const rawTimeFraction =
        (this.logoutTimeThreshold - this.inactiveSince) /
        this.logoutTimeThreshold
      if (rawTimeFraction > 0.5) {
        this.strokeColor = '#00FF00'
      } else if (rawTimeFraction > 0.2) {
        this.strokeColor = '#FFFF00'
      } else {
        this.strokeColor = '#FF0000'
      }
      return (
        rawTimeFraction - (1 / this.logoutTimeThreshold) * (1 - rawTimeFraction)
      )
    },
    setCircleDasharray() {
      this.circleDasharray = `${(this.calculateTimeFraction() * 283).toFixed(
        0,
      )} 283`
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.activateActivityTracker()
    }
  },
  beforeDestroy() {
    this.deactivateActivityTracker()
  },
}
</script>

<style lang="sass" scoped>
.modal-content
  overflow: visible
/* Sets the containers height and width */
.base-timer
  margin-left: auto
  margin-right: auto
  position: relative
  height: 300px
  width: 300px

/* Removes SVG styling that would hide the time label */
.base-timer__circle
  fill: none
  stroke: none

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed
  stroke-width: 7px
  stroke: grey

.base-timer__label
  position: absolute

  /* Size should match the parent container */
  width: 300px
  height: 300px

  /* Keep the label aligned to the top */
  top: 0

  /* Create a flexible box that centers content vertically and horizontally */
  display: flex
  align-items: center
  justify-content: center

  /* Sort of an arbitrary number; adjust to your liking */
  font-size: 48px

.base-timer__path-remaining
  /* Just as thick as the original ring */
  stroke-width: 7px

  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round

  /* Makes sure the animation starts at the top of the circle */
  transform: rotate(90deg)
  transform-origin: center

  /* One second aligns with the speed of the countdown timer */
  transition: 1s linear all

.base-timer__svg
  /* Flips the svg and makes the animation to move left-to-right */
  transform: scaleX(-1)
</style>
